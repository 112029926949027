import Swiper, { Navigation, Mousewheel } from 'swiper';
import 'swiper/css';

const map = new WeakMap();

function init(container: HTMLElement | Document = document) {
    const elements = container.querySelectorAll<HTMLElement>('.js-certificates-slider-wrapper');

    const sliderInitedEvent = new Event('slider-inited');

    elements.forEach((element) => {
        const slidesLength = Array.from(element.querySelectorAll('.swiper-slide')).length;
        const sliderEl = element.querySelector<HTMLElement>('.js-certificates-slider');

        let isNeedToInitSwiper = slidesLength > 1;

        const checkIsNeedToInitSwiper = () => {
            isNeedToInitSwiper = slidesLength > 1;

            if (isNeedToInitSwiper) {
                element.classList.add('navigation-show');
            } else {
                element.classList.remove('navigation-show');
            }
        };

        checkIsNeedToInitSwiper();

        map.set(element, checkIsNeedToInitSwiper);

        window.addEventListener('resize', checkIsNeedToInitSwiper);

        if (isNeedToInitSwiper && sliderEl) {
            const spaceBetween =
                window.innerWidth < 768 ? 8 : window.innerWidth < 1024 ? 24 : window.innerWidth ? 40 : 60;
            const nextNavigation = element.querySelector<HTMLElement>('.js-catalog-certificates__next');
            const prevNavigation = element.querySelector<HTMLElement>('.js-catalog-certificates__prev');
            const instance = new Swiper(sliderEl, {
                modules: [Navigation, Mousewheel],
                touchStartPreventDefault: false,
                navigation: {
                    nextEl: nextNavigation,
                    prevEl: prevNavigation,
                },
                touchMoveStopPropagation: true,
                spaceBetween,
                mousewheel: {
                    releaseOnEdges: false,
                },
                on: {
                    init: () => {
                        document.dispatchEvent(sliderInitedEvent);
                    },
                },
            });

            const certificates = Array.from(document.querySelectorAll<HTMLElement>('.js-c-certificate'));

            certificates.forEach((certificate) => {
                const colorElement = certificate.querySelector('.js-c-certificate__color');

                if (colorElement) {
                    certificate.style.background = colorElement.innerHTML;
                }
            });
            map.set(element, instance);
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    const elements = container.querySelectorAll<HTMLElement>('.js-certificates-slider-wrapper');

    elements.forEach((element) => {
        const instance = map.get(element);
        const handler = map.get(element);

        if (handler) {
            window.removeEventListener('resize', handler);
        }
        if (instance) {
            instance.destroy();
        }

        map.delete(element);
    });
}

export default { init, destroy };
