import '@/webpack-imports';
import '@/sentry';
import '@/custom-elements';
import '@/metrics';
import { applyPolyfills } from '@/polyfills';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
import barba from '@barba/core';
import barbaCss from '@barba/css';
import Axios from 'axios';
import { initCustomElements } from './custom-elements';
import { initBaseView } from '@/views/_base';
import lazyView from '@/views/_lazy';
import BaseTransition from '@/transitions/base';
import { createLayoutGrid } from '@/utils/grid';
import { calculateScrollbarWidth } from '@/modules/calculate-scrollbar-width';
import vhMobileFix from '@/modules/vh-mobile-fix';
import ajaxForms from '@/inits/ajax-forms';
import { preloader } from '@/components/preloader';
import cursor from '@/components/cursor';
import { createInitialBaseTimeline } from './animations/initial-base';
import viewportWidth, { DEVICE_WIDTH } from './utils/viewport-width';
import handleActions from '@/inits/components/header/header-actions';
import headerPopups from '@/inits/components/header/header-popups';
import headerCartHandlers from '@/inits/components/header/header-cart-handlers';
import headerMenu from '@/inits/components/header/header-menu';
import footerParallaxAnimation from '@/animations/components/footer-parallax';
import phoneConfirmInputs from '@/inits/components/header/phone-confirm-inputs';
import 'requestidlecallback-polyfill';
import changeTheme from '@/inits/change-theme';

document.documentElement.classList.add('js-ready');

gsap.config({ nullTargetWarn: false });
Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

applyPolyfills().then(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    viewportWidth.init();
    changeTheme.init();

    if (DEVICE_WIDTH.isDesktop) {
        cursor.init();
    }

    vhMobileFix();
    calculateScrollbarWidth();

    if (NODE_ENV === 'development') {
        createLayoutGrid();
    }

    handleActions.init();

    headerPopups.init();
    headerMenu.init();
    headerCartHandlers.init();
    phoneConfirmInputs.init();

    const appQueue = new IdleQueue();
    const footer = document.querySelector('.js-footer');

    if (footer) {
        appQueue.pushTask(() => {
            ajaxForms.init(footer);
        });
    }

    console.log('trigger');

    footerParallaxAnimation.init();

    appQueue.pushTask(initCustomElements);

    initBaseView();

    const animation = createInitialBaseTimeline();

    // Initial load
    preloader
        .loadAssets()
        .then(() => preloader.playAnimation())
        .then(() => {
            barba.use(barbaCss);

            barba.init({
                transitions: [BaseTransition],
                views: [
                    lazyView('index-page', () =>
                        import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('catalog-page', () =>
                        import(/* webpackChunkName: "catalog-page", webpackPrefetch: true */ './views/catalog').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('news-page', () =>
                        import(/* webpackChunkName: "news-page", webpackPrefetch: true */ './views/news').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('researches-page', () =>
                        import(
                            /* webpackChunkName: "researches-page", webpackPrefetch: true */ './views/researches'
                        ).then((m) => m.default),
                    ),
                    lazyView('reviews-page', () =>
                        import(/* webpackChunkName: "reviews-page", webpackPrefetch: true */ './views/reviews').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('partners-page', () =>
                        import(/* webpackChunkName: "partners-page", webpackPrefetch: true */ './views/partners').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('partner-detail-page', () =>
                        import(
                            /* webpackChunkName: "partner-detail-page", webpackPrefetch: true */
                            './views/partner-detail'
                        ).then((m) => m.default),
                    ),
                    lazyView('about-page', () =>
                        import(/* webpackChunkName: "about-page", webpackPrefetch: true */ './views/about').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('news-detail-page', () =>
                        import(
                            /* webpackChunkName: "news-detail-page", webpackPrefetch: true */ './views/news-detail'
                        ).then((m) => m.default),
                    ),
                    lazyView('password-change-page', () =>
                        import(
                            /* webpackChunkName: "password-change-page", webpackPrefetch: true */
                            './views/password-change'
                        ).then((m) => m.default),
                    ),
                    lazyView('personal-page', () =>
                        import(
                            /* webpackChunkName: "personal-page", webpackPrefetch: true */
                            './views/personal'
                        ).then((m) => m.default),
                    ),
                    lazyView('personal-history-page', () =>
                        import(
                            /* webpackChunkName: "personal-history-page", webpackPrefetch: true */
                            './views/personal-history'
                        ).then((m) => m.default),
                    ),
                    lazyView('personal-settings-page', () =>
                        import(
                            /* webpackChunkName: "personal-settings-page", webpackPrefetch: true */
                            './views/personal-settings'
                        ).then((m) => m.default),
                    ),
                    lazyView('product-page', () =>
                        import(
                            /* webpackChunkName: "product-page", webpackPrefetch: true */
                            './views/product'
                        ).then((m) => m.default),
                    ),
                    lazyView('order-page', () =>
                        import(
                            /* webpackChunkName: "order-page", webpackPrefetch: true */
                            './views/order'
                        ).then((m) => m.default),
                    ),
                    lazyView('order-thanks-page', () =>
                        import(
                            /* webpackChunkName: "order-thanks-page", webpackPrefetch: true */
                            './views/order-thanks'
                        ).then((m) => m.default),
                    ),
                    lazyView('for-business-page', () =>
                        import(
                            /* webpackChunkName: "for-business-page", webpackPrefetch: true */
                            './views/for-business'
                        ).then((m) => m.default),
                    ),
                    lazyView('privacy-policy-page', () =>
                        import(
                            /* webpackChunkName: "privacy-policy-page", webpackPrefetch: true */
                            './views/privacy-policy'
                        ).then((m) => m.default),
                    ),
                    lazyView('cookie-policy-page', () =>
                        import(
                            /* webpackChunkName: "cookie-policy-page", webpackPrefetch: true */
                            './views/cookie-policy'
                        ).then((m) => m.default),
                    ),
                    lazyView('returns-and-exchange-page', () =>
                        import(
                            /* webpackChunkName: "returns-and-exchange-page", webpackPrefetch: true */
                            './views/returns-and-exchange'
                        ).then((m) => m.default),
                    ),
                    lazyView('legal-information-page', () =>
                        import(
                            /* webpackChunkName: "legal-information-page", webpackPrefetch: true */
                            './views/legal-information'
                        ).then((m) => m.default),
                    ),
                    lazyView('text-template-page', () =>
                        import(
                            /* webpackChunkName: "text-template-page", webpackPrefetch: true */
                            './views/text-template'
                        ).then((m) => m.default),
                    ),
                    lazyView('not-found-page', () =>
                        import(
                            /* webpackChunkName: "not-found-page", webpackPrefetch: true */
                            './views/not-found'
                        ).then((m) => m.default),
                    ),
                    lazyView('media', () =>
                        import(
                            /* webpackChunkName: "media", webpackPrefetch: true */
                            './views/media'
                        ).then((m) => m.default),
                    ),
                    lazyView('faq', () =>
                        import(
                            /* webpackChunkName: "faq", webpackPrefetch: true */
                            './views/faq'
                        ).then((m) => m.default),
                    ),
                ],
                prefetchIgnore: true,
                preventRunning: true,
                prevent: ({ el }) => el.tagName === 'A' && !!el.closest('#bx-panel'),
                timeout: 5000,
                debug: NODE_ENV === 'development',
            });

            requestAnimationFrame(() => {
                animation.play();
            });
        });

    function setVisibilityState() {
        if (document.visibilityState === 'hidden') {
            document.documentElement.classList.add('is-document-hidden');
        } else {
            document.documentElement.classList.remove('is-document-hidden');
        }
    }

    setVisibilityState();
    document.addEventListener('visibilitychange', setVisibilityState);
});

// Always at the end
// module.hot?.accept();
