import type { Swiper as SwiperType } from 'swiper';
import gsap from 'gsap';
import { fetchSwiper } from '../dynamic-modules';
import { ease1 } from '@/animations/easings';
import { DEVICE_WIDTH } from '../utils/viewport-width';

export const map = new Map<Element, any>();

function generateNavButton(direction: 'prev' | 'next') {
    const button = document.createElement('button');
    button.className = `slider-nav-btn slider-nav-btn--${direction} js-slider-${direction}`;
    button.innerHTML = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.202 2.95788L11.0424 1.09002L4.88143e-07 11.7408L4.67297e-07 12.2177L11.0424 22.9082L13.202 21.0006L5.37861 13.4894L24 13.4894L24 10.4691L5.37861 10.4691L13.202 2.95788Z" fill="white"/>
    </svg>
    `;
    button.setAttribute('aria-label', `${direction === 'prev' ? 'Предыдущий' : 'Следующий'} слайд`);
    return button;
}

export function createNav(swiper: SwiperType, state: { isAnimating: boolean }, sliderNav?: Element | null) {
    if (sliderNav) {
        const navigateBackwards = () => {
            if (!state.isAnimating) {
                swiper.slidePrev();
            }
        };

        const navigateForwards = () => {
            if (!state.isAnimating) {
                swiper.slideNext();
            }
        };

        const fragment = document.createDocumentFragment();
        const prevButton = generateNavButton('prev');
        fragment.appendChild(prevButton);
        prevButton.addEventListener('click', navigateBackwards);
        map.set(prevButton, navigateBackwards);

        const nextButton = generateNavButton('next');
        fragment.appendChild(nextButton);
        nextButton.addEventListener('click', navigateForwards);
        map.set(nextButton, navigateForwards);

        sliderNav.appendChild(fragment);
    }
}

export function destroyNav(containerEl: Element) {
    const prevButton = containerEl.querySelector<HTMLElement>('.js-slider-prev');
    const nextButton = containerEl.querySelector<HTMLElement>('.js-slider-next');

    [prevButton, nextButton].forEach((btn) => {
        if (btn) {
            const listener = map.get(btn);

            if (listener) {
                btn.removeEventListener('click', listener);
                map.delete(btn);
            }
        }
    });
}

export function createCounter(swiper: SwiperType, container?: Element | null) {
    if (container) {
        const fragment = document.createDocumentFragment();
        const current = document.createElement('div');
        current.className = 'slider-counter--current';
        current.textContent = `${swiper.realIndex + 1}`;
        fragment.appendChild(current);

        const total = document.createElement('div');
        total.className = 'slider-counter--total';
        total.textContent = `${
            swiper.slides.filter((slide) => !slide.classList.contains('swiper-slide-duplicate')).length
        }`;
        fragment.appendChild(total);

        swiper.on('slideChange', () => {
            current.textContent = `${swiper.realIndex + 1}`;
        });

        container.appendChild(fragment);
    }
}

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-actions-slider')).forEach(async (el) => {
        const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

        if (slides.length > 1) {
            const { Swiper, Lazy } = await fetchSwiper();
            const state = { isAnimating: false };

            const changeSlide = (swiper: SwiperType) => {
                const isNextDirection =
                    (swiper.previousIndex < swiper.activeIndex ||
                        (swiper.previousIndex === slides.length - 1 && swiper.activeIndex === 0)) &&
                    !(swiper.previousIndex === 0 && swiper.activeIndex === slides.length - 1);

                const currentImg = swiper.slides[swiper.previousIndex].querySelector<HTMLElement>(
                    '.js-actions-slider-slide__img-wrapper',
                );
                const currentLabel = swiper.slides[swiper.previousIndex].querySelector<HTMLElement>(
                    '.js-actions-slider-slide__label',
                );
                const currentCounter = swiper.slides[swiper.previousIndex].querySelector<HTMLElement>(
                    '.js-sale-counter',
                );
                const currentTitle = swiper.slides[swiper.previousIndex].querySelector<HTMLElement>(
                    '.js-actions-slider-slide__title',
                );
                const currentBtn = swiper.slides[swiper.previousIndex].querySelector<HTMLElement>(
                    '.js-actions-slider-slide__btn',
                );
                const currentSaleEnd = swiper.slides[swiper.previousIndex].querySelector<HTMLElement>(
                    '.js-sale-title',
                );
                const nextSaleEnd = swiper.slides[swiper.activeIndex].querySelector<HTMLElement>(
                    '.js-sale-title',
                );
                const nextCounter = swiper.slides[swiper.activeIndex].querySelector<HTMLElement>(
                    '.js-sale-counter',
                );
                const nextImg = swiper.slides[swiper.activeIndex].querySelector<HTMLElement>(
                    '.js-actions-slider-slide__img-wrapper',
                );
                const nextLabel = swiper.slides[swiper.activeIndex].querySelector<HTMLElement>(
                    '.js-actions-slider-slide__label',
                );
                const nextTitle = swiper.slides[swiper.activeIndex].querySelector<HTMLElement>(
                    '.js-actions-slider-slide__title',
                );
                const nextBtn = swiper.slides[swiper.activeIndex].querySelector<HTMLElement>(
                    '.js-actions-slider-slide__btn',
                );
                const nextTimer = swiper.slides[swiper.activeIndex].querySelector<HTMLElement>(
                    '.js-sale-counter',
                );
                const nextTimerTitle = swiper.slides[swiper.activeIndex].querySelector<HTMLElement>(
                    '.js-sale-title',
                );

                const tl = gsap.timeline({
                    defaults: { ease: ease1 },
                    onStart: () => {
                        state.isAnimating = true;
                        swiper.allowTouchMove = false;
                    },
                    onComplete: () => {
                        state.isAnimating = false;

                        if (!DEVICE_WIDTH.isDesktop) {
                            swiper.allowTouchMove = true;
                        }
                    },
                });

                tl.fromTo(
                    [currentLabel, currentTitle, currentBtn, currentCounter, currentSaleEnd],
                    { y: 0, opacity: 1 },
                    {
                        duration: 0.6,
                        y: 15,
                        opacity: 0,
                        stagger: 0.1,
                    },
                )
                    .fromTo(
                        currentImg,
                        { xPercent: 0 },
                        { xPercent: isNextDirection ? -100 : 100, duration: 1, ease: 'power3.inOut' },
                        '-=0.3',
                    )
                    .fromTo(
                        nextImg,
                        { xPercent: isNextDirection ? 100 : -100 },
                        { xPercent: 0, duration: 1, ease: 'power3.inOut' },
                        '-=1',
                    )
                    .fromTo(
                        [nextLabel, nextTitle, nextBtn, nextCounter, nextSaleEnd],
                        { y: 15, opacity: 0 },
                        {
                            duration: 0.6,
                            y: 0,
                            opacity: 1,
                            stagger: 0.1,
                        },
                    );
            };

            const slider = new Swiper(el, {
                modules: [Lazy /* Autoplay */],
                allowTouchMove: !DEVICE_WIDTH.isDesktop,
                slidesPerView: 1,
                watchSlidesProgress: true,
                virtualTranslate: true,
                speed: 0,
                preloadImages: false,
                lazy: {
                    loadPrevNext: true,
                    checkInView: true,
                },
                rewind: true,
                on: {
                    init: (swiper) => {
                        if (
                            typeof swiper.params.slidesPerView === 'number' &&
                            slides.length <= swiper.params.slidesPerView
                        ) {
                            swiper.disable();
                        }

                        slides
                            .filter((_, i) => i !== swiper.activeIndex)
                            .forEach((el) => {
                                gsap.set(el.querySelector('.js-actions-slider-slide__img-wrapper'), { xPercent: 100 });
                            });
                    },
                    afterInit: (swiper) => {
                        const sliderNavs = Array.from(el.querySelectorAll<HTMLElement>('.js-slider-nav'));
                        const counters = Array.from(el.querySelectorAll<HTMLElement>('.js-slider-counter'));
                        swiper.el.classList.add('swiper-initialized');

                        sliderNavs.forEach((sliderNav) => {
                            createNav(swiper, state, sliderNav);
                        });

                        counters.forEach((counter) => {
                            createCounter(swiper, counter);
                        });
                    },
                    slideChange: (swiper) => {
                        requestAnimationFrame(() => changeSlide(swiper));
                    },
                    destroy: () => {
                        destroyNav(el);
                    },
                    resize: (swiper) => {
                        if (
                            typeof swiper.params.slidesPerView === 'number' &&
                            slides.length <= swiper.params.slidesPerView
                        ) {
                            swiper.disable();
                        } else {
                            swiper.enable();
                        }
                    },
                    slideChangeTransitionStart: (swiper) => {
                        if (!DEVICE_WIDTH.isDesktop) {
                            swiper.allowTouchMove = false;
                        }
                    },
                },
            });
            map.set(el, slider);
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll('.js-actions-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
